import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, NamedLink } from '../../../components';

import css from './LoginForm.module.css';
import IconEyeClose from '../../../components/IconEyeClose/IconEyeClose';
import IconEyeOpen from '../../../components/IconEyeOpen/IconEyeOpen';
import IconInfo from '../../../components/IconInfo/IconInfo';

const LoginFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
      } = fieldRenderProps;

      // email
      const emailLabel = intl.formatMessage({
        id: 'LoginForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'LoginForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'LoginForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'LoginForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'LoginForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'LoginForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'LoginForm.passwordRequired',
      });
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const passwordRecoveryLink = (
        <NamedLink name="PasswordRecoveryPage" className={css.recoveryLink}>
          <FormattedMessage id="LoginForm.forgotPassword" />
        </NamedLink>
      );

      const [showPassword, setShowPassword] = useState(false);
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.loginForm}>
            <div className={css.authHead}>
              <h2>
                {' '}
                <FormattedMessage id="LoginForm.welcomeBack" />
              </h2>
              <p>Welcome back! Please enter your details.</p>
            </div>
            <div className={classNames(css.formRow, css.fullWidth)}>
              <div className={css.formFld}>
                <FieldTextInput
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  // label={emailLabel}
                  placeholder={emailPlaceholder}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
              </div>
            </div>
            <div className={classNames(css.formRow, css.fullWidth)}>
              <div className={css.formFld}>
                <FieldTextInput
                  type={showPassword ? 'text' : 'password'}
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="current-password"
                  // label={passwordLabel}
                  placeholder={passwordPlaceholder}
                  validate={passwordRequired}
                />
                <span className={css.showHidePass} onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <IconEyeClose /> : <IconEyeOpen />}
                </span>
              </div>
            </div>
          </div>
          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.recoveryLinkInfo}>
                <FormattedMessage
                  id="LoginForm.forgotPasswordInfo"
                  values={{ passwordRecoveryLink }}
                />
              </span>
            </p>
            <PrimaryButton
              className={css.submitBtn}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage id="LoginForm.logIn" />
            </PrimaryButton>
            {/* <div className={css.alreadyHaveAccount}>
              <FormattedMessage id="SignupForm.alreadyHaveAnAccount" />{' '}
              <NamedLink name="LoginPage">
                <FormattedMessage id="SignupForm.signIn" />
              </NamedLink>
            </div> */}
          </div>
        </Form>
      );
    }}
  />
);

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
