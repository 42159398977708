import React from 'react';
import { func } from 'prop-types';

import { requiredFieldArrayCheckbox } from '../../../util/validators';
import { FieldCheckboxGroup } from '../../../components';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';

import css from './TermsAndConditions.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import routeConfiguration from '../../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../../util/routes';

const KEY_CODE_ENTER = 13;

const TermsAndConditions = props => {
  const { onOpenTermsOfService, onOpenPrivacyPolicy, intl } = props;
  const history = useHistory();

  const handleClick = callback => e => {
    e.preventDefault();
    callback(e);
  };
  const handleKeyUp = callback => e => {
    // Allow click action with keyboard like with normal links
    if (e.keyCode === KEY_CODE_ENTER) {
      callback();
    }
  };

  const termsLink = (
    <a
      className={css.termsLink}
      href={createResourceLocatorString('TermsOfServicePage', routeConfiguration(), {}, {})}
      target="_blank" // This attribute opens the link in a new tab
      rel="noopener noreferrer" // Best practice for security
    >
      <FormattedMessage id="AuthenticationPage.termsAndConditionsTermsLinkText" />
    </a>
  );

  const privacyLink = (
    <a
      className={css.termsLink}
      href={createResourceLocatorString('PrivacyPolicyPage', routeConfiguration(), {}, {})}
      target="_blank" // This attribute opens the link in a new tab
      rel="noopener noreferrer" // Best practice for security
    >
      <FormattedMessage id="AuthenticationPage.termsAndConditionsPrivacyLinkText" />
    </a>
  );

  return (
    <div className={css.root}>
      <FieldCheckboxGroup
        name="terms"
        id="terms-accepted"
        optionLabelClassName={css.finePrint}
        options={[
          {
            key: 'tos',
            label: intl.formatMessage(
              { id: 'AuthenticationPage.termsAndConditionsAcceptText' },
              { termsLink, privacyLink }
            ),
          },
        ]}
        validate={requiredFieldArrayCheckbox(
          intl.formatMessage({ id: 'AuthenticationPage.termsAndConditionsAcceptRequired' })
        )}
      />
      <FieldCheckboxGroup
        name="cookies"
        id="cookies-accepted"
        optionLabelClassName={css.finePrint}
        options={[
          {
            key: 'cookies-and-privacy',
            label: intl.formatMessage(
              { id: 'AuthenticationPage.cookiesAcceptText' },
              { termsLink, privacyLink }
            ),
          },
        ]}
        validate={requiredFieldArrayCheckbox(
          intl.formatMessage({ id: 'AuthenticationPage.cookiesAcceptTextRequired' })
        )}
      />
    </div>
  );
};

// TermsAndConditions.defaultProps = {};

TermsAndConditions.propTypes = {
  onOpenTermsOfService: func.isRequired,
  onOpenPrivacyPolicy: func.isRequired,
  intl: intlShape.isRequired,
};

export default TermsAndConditions;
