import React, { useEffect, useState } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  NamedLink,
  FieldSelect,
  IconEdit,
  IconInquiry,
  BirthdayInput,
  FieldRadioButton,
} from '../../../components';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { pathByRouteName } from '../../../util/routes';
import css from './SignupForm.module.css';
import IconEyeOpen from '../../../components/IconEyeOpen/IconEyeOpen';
import IconEyeClose from '../../../components/IconEyeClose/IconEyeClose';
import IconInfo from '../../../components/IconInfo/IconInfo';
import { getSupportedCountryCodes } from '../../../components/StripeBankAccountTokenInputField/StripeBankAccountTokenInputField.util';
import { useConfiguration } from '../../../context/configurationContext';
import { USER_TYPE } from '../../../helpers/enums';
import moment from 'moment';
import {
  DEFAULT_LANG_OPTIONS,
  PATIENT_OPTIONS,
  doctorSalutationOptions,
  englishPatientOptions,
  genderOptions,
  greekPatientOptions,
  salutationOptions,
  userTypeOptions,
} from '../../../config/customConfig';
import { isLocaleGreek, sortArrayAlphabetically } from '../../../util/genericHelpers';
import SidebarProvider from '../SidebarProvider';
import SidebarUser from '../SidebarUser';
import { useHistory, useParams, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import RenderVoucher from './RenderVoucher';
import { useSelector } from 'react-redux';
import { verifyCodeSelector } from '../../../ducks/global.duck';
import RenderVoucherOrId from './RenderVoucher';

const useQueryParams = () => {
  const { search } = useLocation();
  return new URLSearchParams(search);
};

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        userType,
        viewport,
        form,
        values,
        hideUserType,
      } = fieldRenderProps;

      const history = useHistory();
      const params = useParams();
      const isGreek = isLocaleGreek();
      const routeConfiguration = useRouteConfiguration();
      const config = useConfiguration();
      const queryParams = useQueryParams();

      const [showPassword, setShowPassword] = useState(false);
      const [showMoreContent, setShowMoreContent] = useState(false);
      const [invalidDate, setInvalidDate] = useState(false);

      const { inProgress: voucherVerificationInProgress = null } =
        useSelector(verifyCodeSelector) || {};

      useEffect(() => {
        const typeParam = queryParams.get('userType');
        const isUser = typeParam === 'user';

        if (isUser) {
          form.change('type', USER_TYPE.INDIVIDUAL);
        } else {
          form.change('type', params?.type);
        }
      }, [queryParams, form]);

      const fromParam = queryParams.get('from');
      const isFromBooking = fromParam === 'booking';

      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const handleDateOfBirthChange = newDate => {
        const dob = moment(newDate).format('YYYY-MM-DD');
        form.change('dateOfBirth', dob);

        // Calculate age
        const age = moment().diff(moment(newDate), 'years');

        // Set state based on age
        if (age < 18) {
          setInvalidDate(true);
        } else {
          setInvalidDate(false);
        }
      };

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;
      const doctorSpecialityOptions = isLocaleGreek() ? greekPatientOptions : englishPatientOptions;

      const renderDoctorFields = (
        <>
          <div className={classNames(css.formRow, css.fullWidth)}>
            <div className={css.formFld}>
              <FieldSelect
                id={formId ? `${formId}.speciality` : 'speciality'}
                name="speciality"
                onChange={speciality => form.change('speciality', speciality)}
              >
                <option value="" disabled>
                  {intl.formatMessage({
                    id: 'AuthenticationPage.chooseSpeciality',
                  })}
                </option>
                {doctorSpecialityOptions.map(item => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </FieldSelect>
            </div>
          </div>
        </>
      );

      const countriesLabel = getSupportedCountryCodes(config.stripe.supportedCountries).map(c => {
        return intl.formatMessage({ id: `StripeConnectAccountForm.countryNames.${c}` });
      });

      function sortCountriesAlphabetically() {
        const sortedCountries = [...countriesLabel];

        sortedCountries.sort((a, b) => {
          return a.localeCompare(b);
        });

        return sortedCountries;
      }

      const renderIndividualsFields = (
        <>
          <div className={classNames(css.formRow, css.fullWidth)}>
            <div className={css.formFld}>
              <div className={css.dateOfBirth}>
                {' '}
                {intl.formatMessage({ id: 'SignupForm.dateOfBirth' })}
              </div>
              <BirthdayInput onDateChange={handleDateOfBirthChange} />
            </div>
            {invalidDate ? (
              <span className={css.error}>
                {' '}
                {intl.formatMessage({ id: 'SignupForm.yearValidation' })}
              </span>
            ) : null}
          </div>
          <div className={classNames(css.formRow, css.fullWidth)}>
            <div className={css.formFld}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.city` : 'city'}
                name="city"
                placeholder={intl.formatMessage({
                  id: 'SignupForm.city',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.cityRequired',
                  })
                )}
              />
            </div>
          </div>
          <div className={classNames(css.formRow, css.fullWidth)}>
            <div className={css.formFld}>
              <FieldSelect id={formId ? `${formId}.country` : 'country'} name="country">
                <option disabled value="">
                  {intl.formatMessage({ id: 'StripeConnectAccountForm.countryPlaceholder' })}
                </option>
                {getSupportedCountryCodes(config.stripe.supportedCountries).map((c, i) => (
                  <option key={c} value={c}>
                    {sortCountriesAlphabetically()[i]}
                  </option>
                ))}
              </FieldSelect>
            </div>
          </div>
        </>
      );

      const userTypeLabel = userTypeOptions.find(item => item.key === userType);
      const salutationSelection =
        userType === USER_TYPE.DOCTOR ? doctorSalutationOptions : salutationOptions;
      const signupAsMobile = userTypeOptions.find(item => item.id === userType);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.signupForm}>
            <div className={css.authHead}>
              <h2>
                {isFromBooking ? (
                  <FormattedMessage id="AuthenticationPage.signupToBook" />
                ) : (
                  <FormattedMessage id="AuthenticationPage.signupForm" />
                )}
              </h2>
              <div className={css.viewsidebarcontentMobile}>
                {viewport.width > 0 && viewport.width < 1200 && (
                  <>
                    {' '}
                    <FormattedMessage id="AuthenticationPage.signupAs" />{' '}
                    {isLocaleGreek() ? userTypeLabel?.greekLabel : userTypeLabel?.label}
                    {isLocaleGreek() ? signupAsMobile?.greekLabel : signupAsMobile?.label}
                    <span onClick={() => setShowMoreContent(!showMoreContent)}>
                      <IconInfo />
                    </span>
                  </>
                )}
                {showMoreContent ? (
                  <div
                    className={classNames(
                      css.sidebarMobileContainer,
                      showMoreContent ? css.showContent : null
                    )}
                  >
                    <div className={css.sidebarContent}>
                      <span className={css.close} onClick={() => setShowMoreContent(false)}>
                        &times;
                      </span>
                      {userType === USER_TYPE.DOCTOR ? (
                        <div className={css.sidebarHead}>
                          <h2>
                            {' '}
                            <FormattedMessage id="AuthenticationPage.joinHeading" />
                          </h2>
                          <p>
                            <FormattedMessage id="AuthenticationPage.joinText" />
                          </p>
                        </div>
                      ) : null}
                      {userType === USER_TYPE.DOCTOR ? (
                        <SidebarProvider css={css} />
                      ) : (
                        <SidebarUser css={css} />
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {!hideUserType && (
              <div className={css.typeOptions}>
                {userTypeOptions?.map((u, i) => (
                  <FieldRadioButton
                    key={i}
                    id={u.key}
                    name="type"
                    label={isGreek ? u.greekLabel : u.label}
                    value={u.key}
                    onChange={e => {
                      const value = e.target.value;

                      if (value === USER_TYPE.INDIVIDUAL) {
                        history.push('/signup/individual');
                      } else if (value === USER_TYPE.DOCTOR) {
                        history.push('/signup/doctor');
                      }
                    }}
                    validate={validators.required(
                      intl.formatMessage({
                        id: 'SignupForm.userTypeOptions',
                      })
                    )}
                  />
                ))}
              </div>
            )}
            <div className={classNames(css.formRow, css.nameRow)}>
              <div className={css.formFld}>
                <FieldSelect id={formId ? `${formId}.salutation` : 'salutation'} name="salutation">
                  {salutationSelection.map(s => (
                    <option key={s.key} value={s.key} disabled={s?.disabled}>
                      {isGreek ? s.greekLabel : s.label}
                    </option>
                  ))}
                </FieldSelect>
              </div>
              <div className={css.formFld}>
                <FieldTextInput
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  // label={intl.formatMessage({
                  //   id: 'SignupForm.firstNameLabel',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.firstNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.firstNameRequired',
                    })
                  )}
                />
              </div>
              <div className={css.formFld}>
                <FieldTextInput
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  // label={intl.formatMessage({
                  //   id: 'SignupForm.lastNameLabel',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.lastNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.lastNameRequired',
                    })
                  )}
                />
              </div>
            </div>
            <div className={classNames(css.formRow, css.fullWidth)}>
              <div className={css.formFld}>
                <FieldTextInput
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  // label={intl.formatMessage({
                  //   id: 'SignupForm.emailLabel',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.emailPlaceholder',
                  })}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
              </div>
            </div>
            {userType === USER_TYPE.INDIVIDUAL ? renderIndividualsFields : null}
            {userType === USER_TYPE.DOCTOR ? renderDoctorFields : null}
            <div className={classNames(css.formRow, css.fullWidth)}>
              <div className={css.formFld}>
                <FieldTextInput
                  type={showPassword ? 'text' : 'password'}
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="new-password"
                  // label={intl.formatMessage({
                  //   id: 'SignupForm.passwordLabel',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.passwordPlaceholder',
                  })}
                  validate={passwordValidators}
                />
                <span className={css.showHidePass} onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <IconEyeClose /> : <IconEyeOpen />}
                </span>
              </div>
            </div>
            <div className={classNames(css.formRow, css.fullWidth)}>
              <div className={css.formFld}>
                <FieldSelect
                  id={formId ? `${formId}.preferredLang` : 'preferredLang'}
                  name="preferredLang"
                >
                  {DEFAULT_LANG_OPTIONS.map(s => (
                    <option key={s.key} value={s.key} disabled={s?.disabled}>
                      {isGreek ? s.greekLabel : s.label}
                    </option>
                  ))}
                </FieldSelect>
              </div>
            </div>
            <RenderVoucherOrId
              userType={userType}
              showPassword={showPassword}
              formId={formId}
              intl={intl}
              values={values}
              form={form}
            />
          </div>
          <div className={css.bottomWrapper}>
            <div className={css.terms}>{termsAndConditions}</div>
            <PrimaryButton
              className={css.submitBtn}
              type="submit"
              onClick={() => {
                // only track signups for users
                if (userType === USER_TYPE.INDIVIDUAL) {
                  gtag('event', 'signup_attempt', {
                    send_to: 'AW-11487056950/kGGWCKD-mZAZELaouuUq',
                  });
                }
              }}
              inProgress={submitInProgress}
              disabled={
                invalidDate ||
                submitDisabled ||
                voucherVerificationInProgress ||
                (userType === USER_TYPE.DOCTOR && !values?.speciality)
              }
            >
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
            <div className={css.alreadyHaveAccount}>
              <FormattedMessage id="SignupForm.alreadyHaveAnAccount" />{' '}
              <span
                className={css.alreadyHaveAccountLink}
                onClick={() => {
                  const route = pathByRouteName('LoginPage', routeConfiguration);
                  history.push({
                    pathname: route,
                    state: { from: props.from },
                  });
                }}
              >
                <FormattedMessage id="SignupForm.signIn" />
              </span>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
