import React from 'react';
import { FieldTextInput, IconSpinner } from '../../../components';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearCouponCodeInitialStates,
  verifyCodeSelector,
  verifyCouponCode,
} from '../../../ducks/global.duck';

import css from './SignupForm.module.css';
import Icons from '../../../components/Icons/Icons';

function RenderVoucherOrId(props) {
  const { showPassword, formId, intl, values, form } = props;
  const dispatch = useDispatch();
  const { inProgress, success, error } = useSelector(verifyCodeSelector) || {};

  const onVerifyVoucher = async () => {
    if (values?.voucher) {
      await dispatch(verifyCouponCode({ code: values.voucher }));
    }
  };

  return (
    <div className={classNames(css.formRow, css.fullWidth)}>
      <div className={css.formFld}>
        <div className={css.voucherFld}>
          <FieldTextInput
            className={css.voucherInput}
            type={showPassword ? 'text' : 'voucher'}
            id={formId ? `${formId}.voucher` : 'voucher'}
            name="voucher"
            placeholder={intl.formatMessage({ id: 'SignupForm.voucherPlaceholder' })}
            onBlur={onVerifyVoucher}
          />
          {inProgress ? (
            <div className={css.iconSpinner}>
              <IconSpinner />
            </div>
          ) : (
            <span className={css.verifyButton} onClick={onVerifyVoucher}>
              {intl.formatMessage({ id: 'SignupForm.verifyButton' })}
            </span>
          )}
        </div>
        {success && values?.voucher && !error ? (
          <div className={css.successWrapper}>
            <p className={css.success}>
              {intl.formatMessage({ id: 'SignupForm.voucherSuccess' }, { voucher: values.voucher })}
            </p>
            <p
              className={css.clearButton}
              role="button"
              onClick={() => {
                form.change('voucher', null);
                dispatch(clearCouponCodeInitialStates());
              }}
            >
              <Icons name="deleteIcon" />
            </p>
          </div>
        ) : null}
        {error ? (
          <p className={css.voucherError}>
            {intl.formatMessage({ id: 'SignupForm.voucherError' })}
          </p>
        ) : null}
      </div>
    </div>
  );
}

export default RenderVoucherOrId;
