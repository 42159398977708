import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

function SidebarUser(props) {
  const { css } = props;
  return (
    <div className={css.sidebarBody}>
      <div className={css.howitworks}>
        <h2> {/* <FormattedMessage id="AuthenticationPage.user" /> */}</h2>
        {/* <p>
          <FormattedMessage id="AuthenticationPage.userDescription1" />
        </p> */}
        {/* <p>
          <FormattedMessage id="AuthenticationPage.userDescription2" />
        </p> */}
      </div>
    </div>
  );
}

export default SidebarUser;
