import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Button, NamedLink } from '../../components';
import { USER_TYPE } from '../../helpers/enums';

function SidebarProvider(props) {
  const { css } = props;
  return (
    <div className={css.sidebarBody}>
      <div className={css.howitworks}>
        <h2>
          {' '}
          <FormattedMessage id="AuthenticationPage.hiw" />
        </h2>
        <p>
          <FormattedMessage id="AuthenticationPage.hiwDescription" />
        </p>
        <ul>
          <li>
            <FormattedMessage id="AuthenticationPage.hiw1" />
          </li>
          <li>
            <FormattedMessage id="AuthenticationPage.hiw2" />
          </li>
          <li>
            <FormattedMessage id="AuthenticationPage.hiw3" />
          </li>
          <li>
            <FormattedMessage id="AuthenticationPage.hiw4" />
          </li>
          <li>
            <FormattedMessage id="AuthenticationPage.hiw5" />
          </li>
          {/* <li>
            <FormattedMessage id="AuthenticationPage.hiw6" />
          </li>
          <li>
            <FormattedMessage id="AuthenticationPage.hiw7" />
          </li> */}
        </ul>
      </div>
      <div className={css.faqPage}>
        <NamedLink name="FAQPage">
          <Button>
            {' '}
            <FormattedMessage id="Footer.faq" />
          </Button>
        </NamedLink>
      </div>
      <div className={css.registerAsEmployee}>
        <h2>
          <FormattedMessage id="AuthenticationPage.registerAnIndividual" />
        </h2>
        <p className={css.registerHere}>
          <FormattedMessage id="AuthenticationPage.youCanRegister" />
          <NamedLink name="AuthenticationPageSelector" params={{ type: USER_TYPE.INDIVIDUAL }}>
            <FormattedMessage id="AuthenticationPage.here" />
          </NamedLink>
        </p>
      </div>
    </div>
  );
}

export default SidebarProvider;
